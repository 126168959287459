import { ThemeProvider } from "@emotion/react";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Button,
  createTheme,
} from "@mui/material";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, {  } from "react";

const theme = createTheme({
  palette: {
    background: {
      default: "#c3b5d7",
    },
  },
});

export default function ConfirmAccount() {
  const handleSubmit = (e: any) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(document.location.search);
    const token = location.search.substring(location.search.indexOf('token=')+6);
    const userId = queryParams.get("userId");

    const url: string = `https://zubcheto-eo.com/api/identity/confirmEmail?userId=${userId}&token=${token}`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: "" },
      body: JSON.stringify({ token: token, userId: userId }),
    };
    fetch(url, requestOptions).then((res) =>
      navigate("/SuccessConfirmAccount/")
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StaticImage
            src="../images/avatar-settings.png"
            alt="Zubcho Settings"
            placeholder="blurred"
            layout="fixed"
            width={200}
          />
          <Typography component="h1" variant="h5" sx={{ pt: 4 }}>
            Потвърждаване на профил
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Button
              style={{ backgroundColor: "#4D475F" }}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Потвърди
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
